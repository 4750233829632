/*
NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

button,
input,
textarea,
.card,
.nav-item a {
  border-radius: 2px !important;
}

@font-face {
  font-family: DefaultFont;
  src: url('./../fonts/InfraRegular.otf');
}

* {
  font-family: DefaultFont !important;
}

.navigation .badge-light {
  background-color: transparent;
}

.vertical-layout.vertical-menu-modern.menu-expanded .main-menu {
  width: 280px;
}

.cart-img {
  border: 1px solid #efefef;
  box-shadow: 0px 4px 6px rgba(75, 75, 75, 0.12);
  border-radius: 15px;
}

.heading-cart-sm {
  font-family: Infra;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #2f2f2f;
  font-weight: 900 !important;
}

.apps-in-category {
  font-family: Infra;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #2f2f2f;
  font-weight: 200 !important;
}

.heading-cart-banner {
  font-family: Infra;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 30px;
  color: #2f2f2f;
  font-weight: 400 !important;
}

.heading-section {
  font-family: Infra;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #2f2f2f;
}

.cart-mv-icon {
  position: relative;
  left: 10rem;
}

.btn-rating {
  background: rgba(47, 47, 47, 0.1);
  border: 1.5px solid #2f2f2f !important;
  border-radius: 2px !important;
}

.rating-comments {
  background: rgb(225, 233, 234);
  border-radius: 2px;
  border: solid 1px rgb(199, 216, 217);
}

.app-icon {
  max-width: 74px;
  max-height: 74px;
  border-radius: 15% !important;
}

.app-used-by-1 {
  position: relative;
  left: -15px;
}

.app-used-by-2 {
  position: relative;
  left: -25px;
}

.app-star-icon {
  position: absolute;
  top: 10.5px;
  right: 10px;
}

.pointer {
  cursor: pointer !important;
}

.btn-custom-hover:hover {
  color: white !important;
  background-color: #7ebd5f !important;
  border: #7ebd5f 10px !important;
}

.btn-related-app {
  position: relative;
  top: -20px;
}

.font-size-14px {
  font-size: 14px !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.img-screen {
  width: 100%;
  height: 265px;
  border: 2px;
}

.img-screen-full-size {
  width: 100%;
  height: 100%;
  border: 2px;
}

.hover-dark-link {
  color: dark !important;
}

.hover-dark-link:hover {
  opacity: 0.8;
  color: grey;
}

.position-big-screeenshots {
  // background: red;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: #efefef;
  z-index: 1;
  opacity: 0.98;
  padding-top: 25%;
}

.tooltip-style {
  width: 19rem;
  height: auto;
  z-index: 1 !important;
  background: white;
  border: none;
  border-radius: 20%;
}

.card-popup {
  position: absolute;
  z-index: 1;
  right: 15px;
  width: 20rem;
}

.login-icons-block {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: white;
  background-color: rgb(30, 107, 112);
}

.cls-ok-1 {
  display: none !important;
}

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.modal .modal-header .close {
  padding: 0;
  /* box-shadow: 0 5px 20px 0 rgb(34 41 47 / 10%); */
  border-radius: 6px !important;
  background: #ffffff;
  position: relative;
  left: 0px;
  top: -6px;
  width: 35px;
  height: 35px;
}

.react-grid-layout {
  width: 100% !important;
}

.slick-track {
  display: inline-block !important;
}

.slick-list {
  padding: 0 20px 0 0 !important;
}

.cp-0-5 {
  position: relative !important;
  left: 2px !important;
}

.m-0-5 {
  margin: 10px;
}

.lh-0-9 {
  line-height: 0.9rem !important;
}

.fs-i {
  font-style: italic;
}

// Support
.support-container {
  position: relative;
}

.support-wrapper {
  background: #f9f9f9;
  position: fixed;
  top: 0;
  right: 0;
  width: 360px;
  height: 100%;
  color: #2f2f2f;
}

.iframe-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.open-apps {
  text-decoration: underline;
}

.tab {
  margin-bottom: 10px;
}

.help-btn {
  background-color: #65b4e5 !important;
  color: #fff !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  border: 1.3px solid #65b4e5 !important;
}

.help-btn:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #d3d3d3;
}

.help-btn:active {
  background-color: #fff !important;
  border: 1.3px solid #65b4e5 !important;
  color: #65b4e5;
}

.help-icon {
  margin-right: 12px;
  color: #fff !important;
}

.section-1 {
  padding: 0.9rem 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;

  p {
    margin-bottom: 0;
  }

  button {
    font-size: 1.8rem;
  }
}

.section-2 {
  padding: 2.8rem 1.2rem 5.8rem 1.2rem;
  height: 12rem;
  color: #fff;
  background: #055a60;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.9rem;
}

.section-3 {
  padding: 0 15px;
  margin-top: -63px;
}

.section-card {
  box-shadow: 0px 4px 6px rgba(75, 75, 75, 0.12) !important;
  border-radius: 2px;
}

.spp-card-header {
  background: #efefef !important;
}

.spp-card-body {
  background: #f9f9f9 !important;
  margin-top: -15px;
}

.spp-card-body-2 {
  background: #f9f9f9 !important;
  position: relative;
}

.spp-card-body-content {
  opacity: 1;
  margin-top: -30px;
}

.spp-input-search {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}

.top-answers {
  margin: 0.6rem 0;

  .answers-title {
    color: #b9b9c3;
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
  }

  .answer {
    background: #efefef;
    border-radius: 2px;
    color: #2f2f2f;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    margin: 10px 0;
  }

  .answer-icon {
    margin: 15px;
  }

  .answer-text {
    margin: 12px;
  }
}

.spp-btn {
  display: flex;
  justify-content: space-between;
  height: 46px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.contact {
  background: #055a60 !important;
  border-color: #055a60 !important;
}

.contact:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #055a60;
}

.contact:not(:disabled):not(.disabled):active {
  background: #044c51 !important;
  border-color: #055a60 !important;
}

.request {
  background: #f9f9f9 !important;
  border: 1px solid #055a60 !important;
  color: #055a60 !important;
}

.request:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #c8c8c8;
}

.request:not(:disabled):not(.disabled):active,
.request:not(:disabled):not(.disabled):focus {
  background-color: transparent !important;
  border: 1px solid #055a60 !important;
  color: #055a60 !important;
}

// Section-4
.section-4,
.section-5 {
  padding: 0 15px;
  margin-top: -63px;
}

.spp-contact {
  background: #fff !important;
  border: 1px solid #d8d6de !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  margin: 12px 0;
}

.spp-contact::placeholder {
  color: #232323 !important;
}

.spp-contact-input {
  height: 38px;
}

.spp-contact-text {
  height: 98px;
}

.spp-text-center {
  justify-content: center;
}

//Section-5
.open-issue {
  background: rgba(5, 90, 96, 0.1);
  border: 1px solid #055a60;
  border-radius: 2px;
  padding: 10px 15px;
  margin: 15px 0;
  color: #2f2f2f !important;

  h5 {
    font-weight: 500;
    color: #2f2f2f !important;
  }

  .spp-assigned-to {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    p {
      margin-bottom: 0;
    }
  }

  .assigned-open {
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    height: 20px;
    padding: 3px 10px;
  }
}

// Bot
.bot-wrapper {
  position: fixed;
  bottom: 50px;
  right: 30px;
  width: 360px;
}

.section-bot {
  background: #f9f9f9;
  box-shadow: -10px 4px 24px rgba(0, 0, 0, 0.45);
  border-radius: 6px !important;
  // position: relative;
}

.spp-bot-body {
  margin-top: -15px;
}

.bot-input::placeholder {
  color: #d8d6de !important;
}

.spp-btn-wrapper {
  display: flex;
  justify-content: flex-end;
}

.spp-support-btn {
  border-radius: 150px !important;
  background: #65b4e5 !important;
  border: 1px solid #65b4e5 !important;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.border-radius-top {
  border-radius: 6px 6px 0 0;
}

.border-radius-bottom {
  border-radius: 0 0 6px 6px !important;
}

.card-bot {
  // padding: 1.5rem;
  background: #f9f9f9;
  box-shadow: 0 4px 6px rgba(75, 75, 75, 0.12);
}

.headpfone-icon {
  margin-right: 12px;
}

.active-widget {
  border: solid 1px #ffb81c;
}

.lp-copy-sel__value-container {
  padding: 0;
}

.lp-copy-sel__multi-value {
  margin-left: 6px;
  margin-top: 2px;
}

.lp-copy-sel__placeholder {
  margin-left: 8px;
}

.react-flatpickr.form-control {
  background-color: #fff !important;
  opacity: 1;
}
.custom-file-label {
  overflow: hidden;
  white-space: nowrap;
  padding-right: 8em;
  text-overflow: ellipsis;
}
.flatpickr-time {
  height: 40px !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #a8a8a8 #ffffff;
}

/* All browser exept Mozila */
*::-webkit-scrollbar {
  width: 0px !important;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 12px;
  border: 3px solid #ffffff;
}

.spinner-with-text {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.spinner-text {
  margin-left: -23px;
  font-size: 12px;
}

.animated-skeleton {
  opacity: 0;
  transition: opacity 0.7s ease-in-out;
  animation: waveAnimation 2s infinite;
}

@keyframes waveAnimation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

.switch {
  position: relative;
  height: 1.5rem;
  width: 3rem;
  cursor: pointer;
  appearance: none !important;
  -webkit-appearance: none !important;
  border-radius: 9999px !important;
  background-color: rgba(100, 116, 139, 0.377) !important;
  transition: all 0.3s ease;
}

.switch:checked {
  background-color: rgba(23, 176, 173, 1) !important;
}

.switch::before {
  position: absolute;
  content: '';
  left: calc(1.5rem - 1.6rem);
  top: calc(1.5rem - 1.6rem);
  display: block;
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
  border: 1px solid rgba(100, 116, 139, 0.527);
  border-radius: 9999px !important;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 3px 10px rgba(100, 116, 139, 0.327);
  transition: all 0.3s ease;
}

.switch:hover::before {
  box-shadow: 0 0 0px 8px rgba(0, 0, 0, 0.15);
}

.switch:checked:hover::before {
  box-shadow: 0 0 0px 8px rgba(23, 176, 173, 0.15);
}

.switch:checked:before {
  transform: translateX(100%);
  border-color: rgba(23, 176, 173, 1) !important;
}
